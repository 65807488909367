import { toast } from 'react-toastify'
import { CURRENT_PAGE_NUMBER, NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import { apiSlice } from '../api/apiSlice'
import {
  type OrderResponse,
  type OrderPayload,
  type OrderResponsePayload,
  type OrderMetricsCount,
  type OrderMetricsValue,
} from './orders.type'
import { getErrorMessage } from '../../helpers'
import { ErrorType } from '../user/user.type'

interface QueryParams {
  skip: number
  limit: number
  status?: string
  startDate?: string
  endDate?: string
}

interface MainOrderResponse {
  data: OrderResponsePayload
}

export const orderApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getOrders: builder.query<MainOrderResponse, QueryParams>({
      query: ({ limit, skip, status, startDate, endDate }) => ({
        url: `/order/all?sortDirection=asc&sortField=date&page=${
          skip ?? CURRENT_PAGE_NUMBER
        }&limit=${limit ?? NUMBER_OF_ITEMS_PER_PAGE}${
          status !== undefined && status !== '' && status !== 'all'
            ? `&status=${status}`
            : ''
        }${
          startDate !== undefined && startDate !== ''
            ? `&startDate=${startDate}`
            : ''
        }${endDate !== undefined && endDate !== '' ? `&endDate=${endDate}` : ''}
      `,
        method: 'GET',
      }),
      providesTags: [{ type: 'ORDER', id: 'LIST' }],
    }),
    getOrdersMetricsCount: builder.query<
      OrderMetricsCount,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `/order/metrics/count${
          startDate !== undefined && startDate !== ''
            ? `?startDate=${startDate}`
            : ''
        }${
          endDate !== undefined && endDate !== '' ? `&endDate=${endDate}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: [{ type: 'ORDER', id: 'LIST' }],
    }),
    getOrdersMetricsValue: builder.query<
      OrderMetricsValue,
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `/order/metrics/value${
          startDate !== undefined && startDate !== ''
            ? `?startDate=${startDate}`
            : ''
        }${
          endDate !== undefined && endDate !== '' ? `&endDate=${endDate}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: [{ type: 'ORDER', id: 'LIST' }],
    }),
    getOrder: builder.query<{ data: OrderResponse }, { id: string }>({
      query: ({ id }) => ({
        url: `/order/${id}`,
        method: 'GET',
      }),
      providesTags: [{ type: 'ORDER', id: 'LIST' }],
    }),
    createOrder: builder.mutation<OrderResponse, OrderPayload>({
      query: (payload) => ({
        url: `/order`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'ORDER', id: 'LIST' }],
    }),
    updateOrder: builder.mutation<
      OrderResponse,
      { body: { status: string }; id: string }
    >({
      query: (payload) => ({
        url: `/order/${payload.id}/status`,
        method: 'PATCH',
        body: payload.body,
      }),
      invalidatesTags: [{ type: 'ORDER', id: 'LIST' }],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const data = await queryFulfilled
          toast.success('Order updated successfully')
        } catch (error) {
          toast.error(getErrorMessage(error as ErrorType))
        }
      },
    }),
    requestOrderDelivery: builder.mutation<OrderResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/order/${id}/request-delivery`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'ORDER', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetOrderQuery,
  useGetOrdersQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useGetOrdersMetricsCountQuery,
  useGetOrdersMetricsValueQuery,
  useRequestOrderDeliveryMutation,
} = orderApiSlice
