import React, { ReactNode, useEffect, useState } from 'react'
import Layout from '../../layout'
import {
  useGetOrderQuery,
  useRequestOrderDeliveryMutation,
  useUpdateOrderMutation,
} from '../../redux-toolkits/orders/orders.slice'
import { Navigate, useParams } from 'react-router-dom'
import Spinner, { LoadingOval } from '../../components/spinner/Spinner'
import { formatCurrency } from '../../helpers'
import { formatDate } from 'date-fns'
import { PageHeader } from '../../components/typography/PageHeader'
import { ChartCard } from '../../components/cards/ChartCard'
import { ORDERSTATUS } from '../../constants'
import Icon from '../../components/icon'
import Dropdown from '../../components/inputs/dropdown'
import { StatusTypes } from '../../types/types'
import { Status } from '../../components/cards/statusTag'
import { TableComponent } from '../../components/table'
import { Button } from '../../components/button'

// interface OrderDetailsPageProps {}

const OrderDetailsPage: React.FC = () => {
  const { id } = useParams()

  const [orderInfo, setOrderInfo] = useState<
    { title: string; value: string | ReactNode }[]
  >([])
  const [custInfo, setCustInfo] = useState<{ title: string; value: string }[]>(
    [],
  )
  const [deliveryInfo, setDeliveryInfo] = useState<
    { title: string; value: string }[]
  >([])

  const [updateOrder, { isLoading: updatingOrder }] = useUpdateOrderMutation()

  const handleChangeStatus = async (
    id: string,
    status: string,
  ): Promise<void> => {
    try {
      await updateOrder({ id: id, body: { status: status } })
    } catch (error) {
      console.log(error)
    }
  }

  const [requestDelivery, { isLoading: requestingDelivery }] =
    useRequestOrderDeliveryMutation()

  if (!id) return <Navigate to="/dashboard" replace />

  const {
    data: payload,
    isLoading,
    isError,
    isSuccess,
  } = useGetOrderQuery({ id })

  useEffect(() => {
    setOrderInfo([
      {
        title: 'Total amount',
        value: formatCurrency(payload?.data?.order.amount ?? 0),
      },
      {
        title: 'Order number',
        value: payload?.data?.order.orderNumber ?? '',
      },
      {
        title: 'Order status',
        value: (
          <div
            key={`-status`}
            className="flex items-center gap-2 overflow-visible"
          >
            {!updatingOrder ? (
              <Status
                text={payload?.data?.order.status ?? ''}
                type={
                  (ORDERSTATUS.find(
                    (status) =>
                      status.name.toLowerCase() ===
                      (payload?.data?.order.status ?? '').toLowerCase(),
                  )?.type ?? 'warn') as StatusTypes
                }
              />
            ) : (
              <LoadingOval loaderHeight="20" loaderWidth="20" color="black" />
            )}

            {!updatingOrder && (
              <Dropdown
                menuClassName="max-h-[170px]"
                menuButton={
                  <div className="bg-primary/5 text-primary rounded-full p-2">
                    <Icon
                      id="left-caret"
                      height={10}
                      width={10}
                      className="-rotate-90"
                    />
                  </div>
                }
                onClickMenuItem={(selected) => {
                  handleChangeStatus(id, selected.value)
                }}
                menuItems={ORDERSTATUS.map((status) => {
                  return {
                    name: status.name,
                    value: status.name,
                  }
                })}
                position="left"
              />
            )}
          </div>
        ),
      },
      {
        title: 'Service charge',
        value: formatCurrency(payload?.data?.order.serviceCharge ?? 0),
      },
      {
        title: 'Payment status',
        value: (
          <Status
            text={payload?.data?.order.paymentStatus ?? ''}
            type={
              (payload?.data?.order.paymentStatus.toLowerCase() === 'paid'
                ? 'success'
                : 'warn') as StatusTypes
            }
          />
        ),
      },
      {
        title: 'Order date',
        value: payload?.data?.order.createdAt
          ? formatDate(payload?.data?.order.createdAt, 'dd/mm/yyyy')
          : '',
      },
      {
        title: 'Buy for self',
        value: payload?.data?.order.buyForSelf ? 'Yes' : 'No',
      },
      {
        title: 'Delivery fee',
        value: formatCurrency(payload?.data.order.delivery.charge ?? 0),
      },
    ])

    setCustInfo([
      {
        title: 'First name',
        value: payload?.data.order.personalInformation.firstName ?? '',
      },
      {
        title: 'Last name',
        value: payload?.data.order.personalInformation.lastName ?? '',
      },
      {
        title: 'Phone',
        value: payload?.data.order.personalInformation.phone ?? '',
      },
      {
        title: 'Email',
        value: payload?.data.order.personalInformation.email ?? '',
      },
      {
        title: 'Email notifications',
        value: payload?.data.order.personalInformation.emailNotifications
          ? 'Yes'
          : 'No',
      },
    ])

    setDeliveryInfo([
      {
        title: 'Delivery contact type',
        value: payload?.data.order.delivery.contact ?? '',
      },
      {
        title: 'Delivery contact',
        value: payload?.data.order.delivery.contactNumber ?? '',
      },
      {
        title: 'Delivery address',
        value: payload?.data.order.delivery.address ?? '',
      },
      {
        title: 'Delivery type',
        value: payload?.data.order.delivery.type ?? '',
      },
    ])
  }, [payload, updatingOrder])

  return (
    <Layout>
      {isLoading && <Spinner fullScreen={false} />}

      {isSuccess && !isLoading && (
        <div className="flex flex-col gap-10 mb-20">
          <PageHeader
            title={`Order #${payload?.data?.order.orderNumber ?? ''}`}
            className="!mb-0"
          />

          <div className="w-full flex justify-end">
            <Button
              type="button"
              label="Request delivery"
              onClick={async () => {
                await requestDelivery({ id })
              }}
              loading={requestingDelivery}
              disabled={requestingDelivery}
              primary
              size="medium"
              icon="delivery"
              iconSize={20}
            />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            <ChartCard
              title="Order information"
              chart={
                <div className="grid grid-cols-2 gap-4">
                  {orderInfo.map((order) => {
                    return (
                      <div key={order.title} className="flex flex-col gap-2">
                        <div className="flex flex-col gap-1 font-semibold">
                          <span className="">{order.title}:</span>
                        </div>

                        <div className="">
                          <span className="font-normal">{order.value}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            />

            <ChartCard
              title="Customer information"
              chart={
                <div className="grid grid-cols-2 gap-4">
                  {custInfo.map((customer) => {
                    return (
                      <div key={customer.title} className="flex flex-col gap-2">
                        <div className="flex flex-col gap-1 font-semibold">
                          <span className="">{customer.title}:</span>
                        </div>

                        <div className="">
                          <span className="font-normal">{customer.value}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            />
          </div>

          <div className="">
            <ChartCard
              title="Delivery information"
              chart={
                <div className="grid grid-cols-2 gap-4">
                  {deliveryInfo.map((delivery) => {
                    return (
                      <div key={delivery.title} className="flex flex-col gap-2">
                        <div className="flex flex-col gap-1 font-semibold">
                          <span className="">{delivery.title}:</span>
                        </div>

                        <div className="">
                          <span className="font-normal">{delivery.value}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            />
          </div>

          <TableComponent
            name="Order Items"
            showName
            headers={['Name', 'Unit price', 'Quantity', 'Total price']}
            rows={
              payload
                ? payload.data.orderItems.map((item) => {
                    return {
                      id: item._id,
                      content: [
                        item.product.name,
                        formatCurrency(item.product.price),
                        item.quantity.toLocaleString(),
                        formatCurrency(item.product.price * item.quantity),
                      ],
                    }
                  })
                : []
            }
          />
        </div>
      )}
    </Layout>
  )
}

export default OrderDetailsPage
