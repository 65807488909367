import React, { useState, useMemo } from 'react'
import Icon from '../../components/icon'
// import { Link } from 'react-router-dom'
import BarChart from '../../components/charts/BarChart'
import LineChart from '../../components/charts/LineChart'
import {
  useGetOrdersMetricsCountQuery,
  useGetOrdersMetricsValueQuery,
  useGetOrdersQuery,
} from '../../redux-toolkits/orders/orders.slice'
import { useGetOrderAnalyticsQuery } from '../../redux-toolkits/orders/orderAnalytics.slice'
import Spinner from '../../components/spinner/Spinner'
import { formatCurrency } from '../../helpers'
import Layout from '../../layout'
import OrderTable from '../orders/OrderTable'
import CountCard from '../orders/CountCard'
import { PageHeader } from '../../components/typography/PageHeader'
import { ChartCard } from '../../components/cards/ChartCard'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import { TextInput } from '../../components/inputs/textInput'

export const DashboardPage: React.FC = () => {
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const { data: counts } = useGetOrdersMetricsCountQuery({ startDate, endDate })
  const { data: values } = useGetOrdersMetricsValueQuery({ startDate, endDate })

  const { data: orderAnalytics, isLoading: isLoadingAnalytics } =
    useGetOrderAnalyticsQuery()

  const value = values?.data
  const count = counts?.data

  const orderAnalyticsData = useMemo(
    () => orderAnalytics?.data || [],
    [orderAnalytics],
  )
  const labels = useMemo(
    () => orderAnalyticsData.map((data) => data.day),
    [orderAnalyticsData],
  )
  const values1 = useMemo(
    () => orderAnalyticsData.map((data) => data.total),
    [orderAnalyticsData],
  )
  const values2 = useMemo(
    () => orderAnalyticsData.map((data) => data.count),
    [orderAnalyticsData],
  )

  return (
    <Layout>
      {!isLoadingAnalytics ? (
        <>
          <PageHeader title="Dashboard" />

          <div className="flex justify-end">
            <div className="flex items-center gap-6 flex-wrap">
              <div className="flex flex-col gap-2">
                <p className="text-sm text-sec-black">Start Date:</p>
                <div>
                  <TextInput
                    placeholder="Start date"
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value)
                    }}
                    hasIcon={false}
                    className="!border-black"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <p className="text-sm text-sec-black">End Date:</p>
                <div>
                  <TextInput
                    placeholder="End date"
                    type="date"
                    name="endDate"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value)
                    }}
                    hasIcon={false}
                    readOnly={startDate === '' || startDate === undefined}
                    className="!border-black"
                  />
                </div>
              </div>
            </div>
          </div>

          <CountCardContainer>
            <CountCard
              count={value?.totalOrderValue ?? 0}
              text="Total order"
              isCurrency={true}
            />
            <CountCard
              count={value?.completedOrderValue ?? 0}
              text="Completed order"
              isCurrency={true}
            />
            <CountCard
              count={value?.pendingOrderValue ?? 0}
              text="Pending order"
              isCurrency={true}
            />
            <CountCard
              count={value?.processingOrderValue ?? 0}
              text="Processing order"
              isCurrency={true}
            />
            <CountCard
              count={value?.cancelledOrderValue ?? 0}
              text="Cancelled order"
              isCurrency={true}
            />

            <CountCard
              count={count?.totalOrderCount ?? 0}
              text="Total order count"
              isCurrency={false}
            />
            <CountCard
              count={count?.pendingOrderCount ?? 0}
              text="Pending order count"
              isCurrency={false}
            />
            <CountCard
              count={count?.processingOrderCount ?? 0}
              text="Processing order count"
              isCurrency={false}
            />
            <CountCard
              count={count?.completedOrderCount ?? 0}
              text="Completed order count"
              isCurrency={false}
            />
            <CountCard
              count={count?.cancelledOrderCount ?? 0}
              text="Cancelled order count"
              isCurrency={false}
            />
          </CountCardContainer>

          <div
            className="
            grid grid-flow-row 
            grid-cols-[repeat(auto-fit,_minmax(400px,_1fr))]
            gap-10
            "
          >
            <ChartCard
              title="Average Order"
              value={formatCurrency(463.35)}
              chart={<BarChart responsive labels={labels} data={values1} />}
            />

            <ChartCard
              title="Average Order"
              value={formatCurrency(463.35)}
              chart={<BarChart responsive labels={labels} data={values1} />}
            />

            <div className="col-span-full">
              <ChartCard
                title="Customers"
                value={formatCurrency(463.35)}
                chart={<LineChart responsive labels={labels} data={values1} />}
              />
            </div>

            <ChartCard
              title="Top products by units sold"
              chart={
                <div className="flex flex-col gap-4">
                  {[
                    {
                      name: 'Bottle water',
                      price: 100,
                      quantity: 500,
                      _id: 'djdjd2',
                    },
                    {
                      name: 'Fitness tracker',
                      price: 100,
                      quantity: 5,
                      _id: 'djdjd',
                    },

                    {
                      name: 'Smartwatch',
                      price: 100,
                      quantity: 5,
                      _id: 'djdjd4',
                    },
                  ].map((product) => {
                    return (
                      <div
                        key={product._id}
                        className="flex items-center justify-between gap-4"
                      >
                        <div className="flex flex-col gap-1">
                          <span className="font-normal">{product.name}</span>
                          <span className="font-sec-black text-xs">
                            ({formatCurrency(product.price)})
                          </span>
                        </div>

                        <div className="flex flex-col gap-1 text-right">
                          <span className="font-normal">
                            {product.quantity}
                          </span>
                          <span className="font-sec-black text-xs">
                            ({formatCurrency(product.price * product.quantity)})
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            />

            <ChartCard
              title="Top vendors by total orders"
              chart={
                <div className="flex flex-col gap-4">
                  {[
                    {
                      name: 'Teno',
                      price: 100,
                      quantity: 500,
                      _id: 'djdjd2',
                    },
                    {
                      name: 'Invinci Place',
                      price: 100,
                      quantity: 5,
                      _id: 'djdjd',
                    },

                    {
                      name: 'Bonjour Meals',
                      price: 100,
                      quantity: 5,
                      _id: 'djdjd4',
                    },
                  ].map((vendor) => {
                    return (
                      <div
                        key={vendor._id}
                        className="flex items-center justify-between gap-4"
                      >
                        <div className="flex flex-col gap-1">
                          <span className="font-normal">{vendor.name}</span>
                        </div>

                        <div className="flex flex-col gap-1 text-right">
                          <span className="font-normal">{vendor.quantity}</span>
                          <span className="font-sec-black text-xs">
                            ({formatCurrency(vendor.price * vendor.quantity)})
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            />
          </div>
        </>
      ) : (
        <Spinner fullScreen={false} />
      )}
    </Layout>
  )
}
